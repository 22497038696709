#MloWrapper {
  width: 100%;
  position: relative;
  padding-bottom: 1rem;
  background-color: white;
  background: url('assets/mloBackground.svg') no-repeat center center;
  background-size: cover;
}

@media (max-width: 600px) {
  #MloWrapper {
    margin-top: 14vw;
  }
}

/* styles.css */

/* Container holding the loan officer images */
.loanOfficerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  /* Set the desired height */
}

/* Container for the eclipse image and profile image */
.eclipseImage {
  position: relative;
}

/* Circular mask for the eclipse image */
.eclipse {
  border-radius: 50%;
}

/* Styling for the loan officer's profile image */
.profileImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  /* Adjust dimensions as needed */
  width: 300px;
  height: 300px;
  /* Ensure the profile image is on top of the eclipse */
  z-index: 1;
}

@media screen and (min-width:1024px) and (max-width:1100.7px) {
  .mloDetails {
    padding-left: 1.5rem;
  }
}


@media only screen and (min-width: 320px) and (max-width: 420px) {
  #MloWrapper .social-qr {
    width: 19% !important;
  }
}

/* Custom margin for smaller screens (mobile) */
@media (max-width: 390px) {
  .loanOfficerContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.InfoLeft {
  position: absolute;
  left: 0vw;
  top: 0vw;
  width: 3vw;
}

.InfoRight {
  position: absolute;
  right: 0vw;
}

#mloRow h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 58px;
  letter-spacing: -0.01em;
  color: #1C2253;
}

#mloRow h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #00AAE4;
}

#mloRow h6 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #00AAE4;
}

#mloRow p {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #272F70;
}

@media only screen and (max-width: 991.98px) {
  #mloRow {
    text-align: center;
  }

  .callMe_BranchAddress {
    font-size: 16px !important;
  }

  .loanOfficerContainer {
    padding-bottom: 3.5rem !important;
  }
}

@media only screen and (max-width: 591.98px) {
  #mloRow h4 {
    font-size: 30px;
    line-height: 38px;
  }

  #mloRow h5 {
    font-size: 20px;
    line-height: 25px;
  }

  #mloRow h6 {
    font-size: 16px;
    line-height: 24px;
  }

  #mloRow p {
    font-size: 14px;
    line-height: 20px;
  }
}