.navbar{
    padding: 0rem;
}
@media only screen and (min-width: 766px) {
  .mobile-AccessibilityMenu-show {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .desktop-AccessibilityMenu-show {
    display: none;
  }
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 2;
  }
}